import React from 'react';
import { withRouter } from '../withRouter';
import CustomerReviewInfo from '../components/CustomerReviewInfo';
import CustomerRatingService from '../services/CustomerRatingService';
import { connect } from 'react-redux';

//MUI
import { Box, Stack, Pagination } from '@mui/material';
import styles from "../css/PurchaseSearchResult/PurchaseSearchResult.module.css";

class CustomerReviewsPanel extends React.Component {

  constructor(props) {
    super(props);  

    this.state = {
      pageNumber: 0,
      pageSize: 5,
      pageCount: 0,
      reviews: null
    };   

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  handlePaginationChange(event, page) {
    this.updateReviews(page);
  }

  componentDidMount() {
    this.updateReviews(this.state.pageNumber);
  }

  updateReviews(page) {
    const customerOrganizationId = this.props.customerOrganizationId;
    if (customerOrganizationId) {
      CustomerRatingService.GetReviews({customerOrganizationId: customerOrganizationId, pageNumber: page, pageSize: this.state.pageSize})
        .then(result => {
          this.setState({ reviews: result.collections, pageCount: result.pageCount, pageNumber: result.pageNumber });
        })
        .catch(() => {
          this.props.onSendError("Не удалось получить отзывы для компании");
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Box className={styles.container}>
          <span className={styles.title}>Отзывы</span>
          {this.state.reviews ?
              this.state.reviews.map((review) =>
                  <CustomerReviewInfo review={review} key={review.id}/>
              ) :
              null
          }

          {this.state.reviews && this.state.reviews.length > 0
              ?
              <Box sx={{position: "absolute",
                bottom: "-64px",
                left: 0, mt: 2}}>
                <Stack spacing={2}>
                  <Pagination
                      count={this.state.pageCount ? this.state.pageCount : 1}
                      page={this.state.pageNumber ? this.state.pageNumber : 1}
                      onChange={this.handlePaginationChange}/>
                </Stack>
              </Box>
              : null}
        </Box>
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSendError: (errorMessage) => {
      const notificationAction = {
        type: 'SNACKBAR_NOTIFICATION_SENT',
        notification: {
          message: errorMessage,
          variant: 'error'
        }
      };
      dispatch(notificationAction);
    },
  }
}

export default connect(null, mapDispatchToProps) (withRouter(CustomerReviewsPanel));
