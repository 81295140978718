import React, {useEffect, useState} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import {AuthFooter} from './AuthFooter';
import UserManager from '../../userManager';
import {connect} from 'react-redux';
import {CSSTransition, SwitchTransition} from "react-transition-group";
import Header from "../Header";
import {isMobile} from "react-device-detect";

const AuthLayout = (props) => {
    const location = useLocation();

    useEffect(() => {
        UserManager.getUser().then(user => {
            if (user && !user.expired) {
                props.onPermissions(user.profile.permission);
            } else {
                UserManager.signinRedirect();
            }
        });
    }, [])

    return (
        <>
            <Header/>
            <SwitchTransition mode="out-in">
                <CSSTransition exit={false} key={location.key} classNames="fade" timeout={300}>
                    <div style={isMobile ? {marginBottom: "96px"} : {}}>
                        <Outlet/>
                    </div>
                </CSSTransition>
            </SwitchTransition>
            {
                !isMobile && <AuthFooter/>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onPermissions: (permissions) => {
            const action = {
                type: 'PERMISSIONS',
                permissions: permissions
            };
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
