import React, { Component } from 'react';
import { withRouter } from '../withRouter';
import SelectTagDialog from './SelectTagDialog';
import PurchaseTagsService from '../services/PurchaseTagsService';

import { DateTime } from 'luxon';
import MarkedPurchasesPanel from '../components/MarkedPurchasesPanel';
import PurchaseCard from '../components/PurchaseCard';

import styles from '../css/PurchaseSearchResult/PurchaseSearchResult.module.css'

// MUI
import Pagination from '@mui/material/Pagination';
import { connect } from 'react-redux';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CircularProgress, Box, Chip, Stack } from '@mui/material';
import { withSnackbar } from 'notistack';


class PurchaseSearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenUploadSettingsModal: false,
      isLoadingUploadSettings: false
    };    

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  handlePaginationChange(event, page) {
    //При смене страницы вызывается функция переданная в пропсы от родительского компонента (purchaseSearchPanel или Templates)
    //Сделано так чтобы PurchaseSearchResult мог запускать поиск на конкретном компоненте вызвавшем его - без передачи стейта в этот компонент
    this.props.onPageSearch(page);
  }

  getSourcePlatform = (purchase) => {
    if (purchase.sourcePlatform) {
      return purchase.sourcePlatform.name;
    } else {
      return null;
    }
  }  

  getLotInfo = (purchase) => {
    let value = "";
    if (purchase.lotNumber < 2) {
      return null;
    }
    if (purchase.lotNumber > 1 && purchase.lotNumber < 5) {
      value = purchase.lotNumber + " лота"
    }
    if (purchase.lotNumber > 4) {
      value = purchase.lotNumber + " лот"
    }

    return <>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Chip label={value} />
        </Stack>
      </CardContent>    
    </>
  }
  
  getLaw = (purchase) => {
    switch(purchase.law) {
      case 1:
        return '44-ФЗ';
      case 2:
        return '223-ФЗ';
      case 3:
        return 'Коммерческие';
      case 4: 
        return 'ПП РФ 615';
      default:
        return 'Не определено';
    }    
  }

  getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }  

  getLocalFormatDate = (inputDate) => {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.setZone(localTimezone).toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }

  getDateDiff = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    
    const currentDate = DateTime.now();
    let result = "";
    let dateDiff = date.diff(currentDate, ['days', 'hours']).toObject();

    if (dateDiff.days === 1 || (dateDiff.Days === 0 && dateDiff.hours === 1)) {
      result = result.concat("Остался ")
    }
    else {
      result = result.concat("Осталось ");
    }

    if (dateDiff.days > 0) {
      result = result.concat(dateDiff.days+" д. ");
    }
    if (dateDiff.hours > 1) {
      result = result.concat(parseInt(dateDiff.hours)+" ч. ");
    }
    if (dateDiff.days === 0 && dateDiff.hours < 1 && dateDiff.hours > 0) {
      result = result.concat("менее часа");
    }
    if (dateDiff.hours < 0){
      result = "";
    }

    return result;
  }

  getStage = (purchase) => {
    switch(purchase.stage) {
      case 1:
          if (!purchase.purchaseExpirationAt) {
            return <Typography>Подача заявок</Typography>;
          }

          const dateMsk = this.getFormatDate(purchase.purchaseExpirationAt);
          const dateLocal = this.getLocalFormatDate(purchase.purchaseExpirationAt);

          return (
            <React.Fragment>
              <Typography>Подача заявок до: </Typography>
              <Typography>{dateMsk} (по МСК)</Typography>
              {dateMsk != dateLocal ? <Typography>{dateLocal} (по местному)</Typography> : null}
              <Typography>{this.getDateDiff(purchase.purchaseExpirationAt)}</Typography>
            </React.Fragment>
          );
      case 2:
        return 'Работа комиссии';
      case 3:
        return 'Размещение завершено';
      case 4:
        return 'Размещение отменено';
      case 5:
        return 'Торги не состоялись';
      case 6: 
        return 'Торги запланированы';
      case 7: 
        return 'На исполнении';
      default:
        return 'Не определено';
    }
  }

  render() {
    const purchases = this.props.purchases;

    if (this.props.isLoaded) {
      return (
        <Box className={styles.loaderContainer}>
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <React.Fragment>
          <SelectTagDialog/>
          <MarkedPurchasesPanel onSortingChange={this.props.onPageSearch}/>

          {purchases.map((purchase, key) =>
            <PurchaseCard purchase={purchase} key={key} isMarked={this.props.uploadPurchaseIds.some((uploadPurchaseId) => uploadPurchaseId === purchase.id)} />
          )}

          {purchases.length > 0
            ?
            <Box sx={{ mt: 2 }}>
              <Stack spacing={2}>
                <Pagination
                    count={this.props.pageCount ? this.props.pageCount : 1}
                    page={this.props.pageNumber ? this.props.pageNumber : 1}
                    onChange={this.handlePaginationChange} />
              </Stack>
            </Box>
            : this.props.searchCompleted && <>
                По данному запросу ничего не найдено
              </>
          }
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  if (state.pagesState.currentPage === "PURCHASES") {
    return {
      currentPage: state.pagesState.currentPage,
      purchases: state.purchasesState.purchases,
      pageNumber: state.purchasesState.pageNumber,
      pageSize: state.purchasesState.pageSize,
      total: state.purchasesState.total,
      pageCount: state.purchasesState.pageCount,
      isLoaded: state.purchasesState.isLoaded,
      uploadPurchaseIds: state.markedPurchasesState.uploadPurchaseIds,
      searchCompleted: state.purchasesState.searchCompleted,
      searchInFoundKeywords: state.purchasesState.searchInFoundKeywords
    };
  }
  if (state.pagesState.currentPage === "TEMPLATES") {
    return {
      currentPage: state.pagesState.currentPage,
      purchases: state.templatesState.purchases,
      pageNumber: state.templatesState.pageNumber,
      pageSize: state.templatesState.pageSize,
      total: state.templatesState.total,
      pageCount: state.templatesState.pageCount,
      isLoaded: state.templatesState.isLoaded,
      uploadPurchaseIds: state.markedTemplatesState.uploadPurchaseIds,
      searchCompleted: state.templatesState.searchCompleted,
      searchInFoundKeywords: state.purchasesState.searchInFoundKeywords
    };
  }
  return {
    purchases: [],
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onPurchaseTagRemoved: (purchaseId, tagId) => {
      PurchaseTagsService.removePurchaseTag({purchaseId: purchaseId, tagId: tagId})
        .then(() => {
          const purchaseIds = [purchaseId];
          PurchaseTagsService.getPurchaseTags(purchaseIds)
            .then(result => {
              const action = {
                type: 'REFRESH_PURCHASE_TAGS',
                purchase: result[0]
              };
              dispatch(action);
            })
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при удалении закупки из избранного',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        })
    },
    onPurchaseTagAddCalled: (purchase) => {
      dispatch({type: 'OPEN_DIALOG'});
      PurchaseTagsService.getTags()
      .then(result => {
        const action = {
          type: 'OPEN_TAG_DIALOG',
          selectedPurchase: purchase,
          tags: result
        };
        dispatch(action);
      })
      .catch((error) => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Не удалось получить папки избранного',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      });
    },
    onPurchaseTagChangeCalled: (purchase, tagId) => {
      dispatch({type: 'OPEN_DIALOG'});
      PurchaseTagsService.getTags()
      .then(result => {
        const action = {
          type: 'OPEN_TAG_DIALOG',
          selectedPurchase: purchase,
          selectedTagId: tagId,
          tags: result
        };
        dispatch(action);
      })
      .catch((error) => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Произошла ошибка при добавлении закупки в избранное',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      });
    },
    onAddUploadPurchase: (uploadPurchaseId, currentPage) => {
      const action = {
        type: 'ADD_UPLOAD_PURCHASE',
        uploadPurchaseId: uploadPurchaseId,
        currentPage: currentPage
      }   
      dispatch(action);    
    },
    onRemoveUploadPurchase: (uploadPurchaseId, currentPage) => {
      const action = {
        type: 'REMOVE_UPLOAD_PURCHASE',
        uploadPurchaseId: uploadPurchaseId,
        currentPage: currentPage
      }
      dispatch(action);
    },
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseSearchResult)));
