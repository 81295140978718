import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from '../../withRouter';
import OrganizationResult from './OrganizationResult';
import styles from '../../css/Organizations/Organizations.module.css'

// MUI
import {Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, TextField} from '@mui/material';
import CustomerService from '../../services/CustomerService';
import {ThemeProvider} from "@mui/material/styles";

class Organizations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organization: null,
            organizationInn: this.props.organizationInn,
            activeTab: this.props.activeTab
        };
    }

    render() {
        return (
            <React.Fragment>
                <Card variant="outlined" sx={{minWidth: 275, mt: 1}} className={styles.container}>
                    <CardHeader
                        sx={{p: 0}}
                        title="Поиск по компании"
                        className={styles.containerHeader}
                    />
                    <CardContent sx={{p: 0, pt: 2, pb: 0}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={7}>
                                        <ThemeProvider theme={{
                                            components: {
                                                MuiOutlinedInput: {
                                                    styleOverrides: {
                                                        root: {
                                                            padding: "12px 16px",
                                                            borderRadius: "4px",
                                                            fontSize: "15px",
                                                            maxWidth: "600px",
                                                            fontFamily: "SF Pro Text, serif",
                                                            letterSpacing: "-0.41px",
                                                            '&:hover fieldset': {
                                                                borderColor: "#B2BAC2"
                                                            },
                                                            '&:focus-visible': {
                                                                borderColor: "#1365E3!important",
                                                            },
                                                            '&:focus-visible &:hover fieldset': {
                                                                borderColor: "#1365E3!important",
                                                            },
                                                        },
                                                        input: {
                                                            padding: "0",
                                                        }
                                                    }
                                                }
                                            }
                                        }}>
                                            <TextField
                                                id="inn"
                                                variant="outlined"
                                                placeholder="Введите ИНН. Например, 6401062011"
                                                fullWidth
                                                onChange={(event) => {
                                                    let organizationInn = event.target.value;
                                                    this.setState({
                                                        organizationInn: organizationInn
                                                    });
                                                }}
                                                value={this.state.organizationInn}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            sx={{
                                                borderRadius: '10px',
                                                backgroundColor: '#2B65F0',
                                                padding: '10px 18px',
                                                width: '120px',
                                                fontFamily: 'SF Pro Text, serif',
                                                fontSize: "16px",
                                                textTransform: "unset!important",
                                                letterSpacing: "-0.41px",
                                                lineHeight: "unset!important",
                                                fontWeight: "500",
                                                '&:hover': {
                                                    backgroundColor: "#3060DC!important"
                                                }
                                            }}
                                            onClick={() => this.props.onSearchOrganization(this.state)}
                                            variant="contained"
                                        >Найти</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {this.props.isLoading
                    ?
                    <Box className={styles.loadingContainer}>
                        <CircularProgress/>
                    </Box>
                    :
                    <OrganizationResult/>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        organizationInn: state.organizationsState.organizationInn,
        organization: state.organizationsState.organization,
        isLoading: state.organizationsState.isLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSearchOrganization: (state) => {
            const action = {
                type: 'SEARCH_ORGANIZATION'
            };
            dispatch(action);

            CustomerService.getOrganizationByInn(state.organizationInn)
                .then(result => {
                    const action = {
                        type: 'ORGANIZATION_SEARCH_COMPLETED',
                        organization: result
                    }

                    dispatch(action);
                });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Organizations));
