import React, {Component} from 'react';
import {withRouter} from '../withRouter';
import {connect} from 'react-redux';
import PurchaseTagsService from '../services/PurchaseTagsService';
import {withSnackbar} from 'notistack';

// MUI:
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import DialogContent from "@mui/material/DialogContent";
import {ThemeProvider} from "@mui/material/styles";
import {CSSTransition} from "react-transition-group";


const checkedTagClass = {
    backgroundColor: "#eefff4",
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '10px',
    border: 'solid 1px',
    borderColor: "#b6e9c8",
    width: 'auto',
    borderRadius: "6px",
    fontFamily: "SF Pro Text, sans-serif",
    letterSpacing: "-0.41px"
}

const uncheckedTagClass = {
    backgroundColor: "#f5f5f5",
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '10px',
    border: 'solid 1px',
    borderColor: "#dddada",
    width: 'auto',
    borderRadius: "6px",
    fontFamily: "SF Pro Text, sans-serif",
    letterSpacing: "-0.41px"
}

class SelectTagDialog extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef(null);
    }

    render() {
        const errorDivs = this.props.newTagValidationErrors.map((err, key) => <Grid item xs={12} sx={{color: 'red'}}
                                                                                    key={key}>{err}</Grid>);

        return (
            <React.Fragment>
                <Dialog
                    open={this.props.openSelectTagDialog}
                    onClose={this.props.onClose}
                    PaperProps={{
                        sx: {
                            maxWidth: "600px",
                            width: "600px",
                            borderRadius: "16px",
                            maxHeight: "80%"
                        }
                    }}
                    scroll={"paper"}
                >
                    <DialogTitle sx={{
                        m: 0,
                        p: 3,
                        fontSize: "20px!important",
                        fontWeight: "600",
                        fontFamily: "SF Pro Text, serif",
                        letterSpacing: "-0.41px"
                    }} id="customized-dialog-title">
                        Выберите папки избранного для данной закупки
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={this.props.onClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 16,
                            top: 22,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <img src="/close_icon.svg" alt="Close"/>
                    </IconButton>
                    <DialogContent sx={{borderBottom: "0.5px solid #E8E8E8", paddingTop: "0"}}>
                        <CSSTransition
                            in={this.props.isLoading}
                            timeout={300}
                            classNames='fade'
                            nodeRef={this.ref}
                        >
                            <List sx={{padding: "0"}}>
                                <div ref={this.ref}>
                                    {
                                        this.props.tags.map((tag, key) =>
                                            (this.props.selectedPurchase.tags && this.props.selectedPurchase.tags.some(e => e.id === tag.id))
                                                ?
                                                <ListItem
                                                    sx={{
                                                        marginLeft: "0!important",
                                                        marginRight: "0!important",
                                                    }}
                                                    className='folder_item'
                                                    key={key}
                                                    secondaryAction={
                                                        <CheckIcon/>
                                                    }
                                                    style={checkedTagClass}
                                                    onClick={() => this.props.onRemovePurchaseTag(this.props.selectedPurchase.id, tag, this.props.enqueueSnackbar)}
                                                >
                                                    <ListItemText primary={tag.name}/>
                                                </ListItem>
                                                :
                                                <ListItem
                                                    sx={{
                                                        marginLeft: "0!important",
                                                        marginRight: "0!important"
                                                    }}
                                                    className='folder_item'
                                                    onClick={() => this.props.onAddPurchaseTag(this.props.selectedPurchase.id, tag, this.props.selectedTagId, this.props.enqueueSnackbar)}
                                                    key={key}
                                                    style={uncheckedTagClass}
                                                >
                                                    <ListItemText primary={tag.name}/>
                                                </ListItem>
                                        )
                                    }
                                </div>
                                <ListItem>
                                    <Grid item xs={12}>
                                        {errorDivs}
                                    </Grid>
                                </ListItem>
                            </List>
                        </CSSTransition>
                    </DialogContent>

                    <DialogActions
                        sx={{justifyContent: 'flex-start', pt: 2, pb: 2, paddingLeft: '24px', paddingRight: '24px'}}>
                        <ListItem sx={{padding: "0", justifyContent: "space-between", fontSize: "14px"}}>
                            Добавить новую папку для избранного:
                            <div>
                                <ThemeProvider theme={{
                                    components: {
                                        MuiOutlinedInput: {
                                            styleOverrides: {
                                                root: {
                                                    borderRadius: "4px 0 0 4px",
                                                    fontSize: "14px",
                                                    fontFamily: "SF Pro Text, serif",
                                                    letterSpacing: "-0.41px",
                                                    '& label.Mui-focused': {
                                                        color: 'var(--TextField-brandBorderFocusedColor)',
                                                    },
                                                },
                                            }
                                        }
                                    }
                                }}>
                                    <TextField
                                        size="small"
                                        placeholder="Введите название папки"
                                        onChange={(event) => {
                                            var tag = event.target.value;
                                            this.props.onNewTagChanged(tag);
                                        }}
                                        value={this.props.newTagName}
                                    />
                                    <Button
                                        sx={{
                                            borderRadius: "0 4px 4px 0",
                                            boxShadow: "none",
                                            lineHeight: "1.8",
                                            backgroundColor: "#2B65F0",
                                            '&:hover': {
                                                backgroundColor: "#3060DC"
                                            }
                                        }}
                                        variant="contained"
                                        onClick={() => this.props.onAddTag(this.props.newTagName, this.props.selectedPurchase.id, this.props.selectedTagId)}
                                    > +
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </ListItem>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedPurchase: state.selectTagDialogState.selectedPurchase,
        selectedTagId: state.selectTagDialogState.selectedTagId,
        openSelectTagDialog: state.selectTagDialogState.openSelectTagDialog,
        newTagName: state.selectTagDialogState.newTagName,
        tags: state.selectTagDialogState.tags,
        newTagValidationErrors: state.selectTagDialogState.newTagValidationErrors,
        isLoading: state.selectTagDialogState.isLoading,
        isAnimation: state.selectTagDialogState.isAnimation
    };
}

function mapDispatchToProps(dispatch) {
    return {

        onAddTag: (newTagName, purchaseId, oldTagId) => {
            const action = {
                type: 'SET_IS_LOADING'
            }
            dispatch(action);
            PurchaseTagsService.addTag({name: newTagName})
                .then((response) => {
                    if (response && response.status >= 400 && response.errors) {
                        const action = {
                            type: 'NEW_TAG_ADD_ERROR_OCCURED',
                            newTagValidationErrors: response.errors['Name']
                        };
                        dispatch(action);
                    } else {
                        const newTagId = response;
                        if (purchaseId) {
                            PurchaseTagsService.setPurchaseTag({
                                purchaseId: purchaseId,
                                oldTagId: oldTagId,
                                newTagId: newTagId
                            })
                                .then(() => {
                                    const purchaseIds = [purchaseId];
                                    PurchaseTagsService.getPurchaseTags(purchaseIds)
                                        .then(result => {
                                            const action = {
                                                type: 'REFRESH_PURCHASE_TAGS',
                                                purchase: result[0]
                                            };
                                            dispatch(action);

                                            PurchaseTagsService.getTags().then(res => {
                                                const action = {
                                                    type: 'OPEN_TAG_DIALOG',
                                                    selectedPurchase: result[0],
                                                    tags: res
                                                };
                                                dispatch(action);
                                            })
                                                .catch(() => {
                                                    const notificationAction = {
                                                        type: 'SNACKBAR_NOTIFICATION_SENT',
                                                        notification: {
                                                            message: 'Не удалось получить папки избранного',
                                                            variant: 'error'
                                                        }
                                                    };
                                                    dispatch(notificationAction);
                                                })
                                        })
                                })

                                .catch(() => {
                                    const notificationAction = {
                                        type: 'SNACKBAR_NOTIFICATION_SENT',
                                        notification: {
                                            message: 'Произошла ошибка при добавлении закупки в избранное',
                                            variant: 'error'
                                        }
                                    };
                                    dispatch(notificationAction);
                                })
                        }
                    }
                })
        },
        onNewTagChanged: (value) => {
            const action = {
                type: 'NEW_TAG_FIELD_CHANGED',
                newTagName: value
            };
            dispatch(action);
        },
        onAddPurchaseTag: (purchaseId, newTag, oldTagId, enqueueSnackbar) => {
            const action = {
                type: 'SET_IS_LOADING'
            }
            dispatch(action);

            if (purchaseId) {
                PurchaseTagsService.setPurchaseTag({purchaseId: purchaseId, oldTagId: oldTagId, newTagId: newTag.id})
                    .then(() => {
                        const action = {
                            type: 'TOGGLE_PURCHASE_TAG',
                            newTag: newTag
                        }
                        dispatch(action);
                    })
                    .catch(error => {
                        console.log(error);
                        enqueueSnackbar(
                            'Произошла ошибка при добавлении закупки в избранное',
                            {
                                variant: 'error'
                            });
                    })
            }
        },
        onRemovePurchaseTag: (purchaseId, newTag, enqueueSnackbar) => {
            const action = {
                type: 'SET_IS_LOADING'
            }
            dispatch(action);

            PurchaseTagsService.removePurchaseTag({purchaseId: purchaseId, tagId: newTag.id})
                .then(() => {
                    const action = {
                        type: 'TOGGLE_PURCHASE_TAG',
                        newTag: newTag
                    };
                    dispatch(action);
                })
                .catch(() => {
                    enqueueSnackbar(
                        'Произошла ошибка при удалении закупки из избранного',
                        {
                            variant: 'error'
                        });
                })
        },
        onClose: () => {
            const action = {
                type: 'CLOSE_TAG_DIALOG'
            };
            dispatch(action);
        }
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectTagDialog)));
