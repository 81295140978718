import React from 'react';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker, MobileDatePicker} from '@mui/x-date-pickers';
import { TextField, Box, Typography } from '@mui/material';
import {ThemeProvider} from "@mui/material/styles";
import {isMobile} from "react-device-detect";
import { ruRU } from '@mui/x-date-pickers/locales';

// Пропсы:
// dateFrom: начальная дата периода
// onDateFromChanges: функция для изменения начальной даты периода
// dateTo: конечная дата периода
// onDateToChanges: функция для изменения конечной даты периода
export const CustomDatepickerFromTo = (props) => {
  const [fromError, setFromError] = React.useState('');
  const [toError, setToError] = React.useState('');

  const getErrorMessage = React.useMemo(() => {
    switch (fromError) {
      case 'minDate': {
        return 'Дата ранее минимальной'; 
      }
      case 'maxDate': {
        return 'Конечная дата ранее начальной';
      }
      case 'invalidDate': {
        return 'Некорректная дата';
      }
    }

    switch (toError) {
      case 'maxDate': {
        return 'Дата позже максимальной'; 
      }
      case 'invalidDate': {
        return 'Некорректная дата';
      }
    }

    return null;
  }, [fromError, toError])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <LocalizationProvider localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="ru" dateAdapter={AdapterLuxon}>
              {
                  isMobile ?
                      <MobileDatePicker
                          sx={{
                              padding: '9px 12px',
                          }}
                          value={props.dateFrom}
                          onChange={(value) => {
                              props.onDateFromChanges(value);
                          }}
                          onError={(newError) => {
                              setFromError(newError);
                          }}
                          maxDate={props.dateTo ?? undefined}
                          inputFormat="dd.MM.yyyy"
                          mask='__.__.____'
                          renderInput={(params) => {
                              return (
                                  <ThemeProvider theme={{
                                      components: {
                                          MuiOutlinedInput: {
                                              styleOverrides: {
                                                  root: {
                                                      width: "152px",
                                                  },
                                                  input: {
                                                      padding: "9px 12px",
                                                      borderRadius: "4px",
                                                      fontSize: "16px",
                                                      fontFamily: "SF Pro Text, serif",
                                                      letterSpacing: "-0.41px",
                                                      '&:hover fieldset': {
                                                          borderColor: "#B2BAC2"
                                                      },
                                                      '&:focus-visible': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                      '&:focus-visible &:hover fieldset': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                  },
                                              }
                                          }
                                      }}}>
                                      <TextField
                                          {...params}
                                          inputProps={{
                                              ...params.inputProps,
                                              placeholder: ""
                                          }}
                                      />
                                  </ThemeProvider>
                              );
                          }}
                      />
                      :
                      <DesktopDatePicker
                          sx={{
                              padding: '8px 12px',
                          }}
                          value={props.dateFrom}
                          onChange={(value) => {
                              props.onDateFromChanges(value);
                          }}
                          onError={(newError) => {
                              setFromError(newError);
                          }}
                          maxDate={props.dateTo ?? undefined}
                          inputFormat="dd.MM.yyyy"
                          mask='__.__.____'
                          renderInput={(params) => {
                              return (
                                  <ThemeProvider theme={{
                                      components: {
                                          MuiOutlinedInput: {
                                              styleOverrides: {
                                                  root: {
                                                      width: "152px",
                                                  },
                                                  input: {
                                                      padding: "8px 12px",
                                                      borderRadius: "4px",
                                                      fontSize: "14px",
                                                      fontFamily: "SF Pro Text, serif",
                                                      letterSpacing: "-0.41px",
                                                      '&:hover fieldset': {
                                                          borderColor: "#B2BAC2"
                                                      },
                                                      '&:focus-visible': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                      '&:focus-visible &:hover fieldset': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                  },
                                              }
                                          }
                                      }}}>
                                      <TextField
                                          {...params}
                                          inputProps={{
                                              ...params.inputProps,
                                              placeholder: ""
                                          }}
                                      />
                                  </ThemeProvider>
                              );
                          }}
                      />
              }
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', color: "#848489" }}>
              –
            </Box>
              {
                  isMobile ?
                      <MobileDatePicker
                          value={props.dateTo}
                          onChange={(value) => {
                              //К конечной дате добавляем 23 часа 59 минут 59 секунд и 999 мс чтобы поиск был включая конечную дату
                              if (value) {
                                  const dateWithoutTime = value.set({ hour: 0, minute: 0, second: 0, millisecond: 0});
                                  const modifiedDate = DateTime.fromISO(dateWithoutTime).plus({hours: 23, minutes: 59, seconds: 59, milliseconds: 999});

                                  props.onDateToChanges(modifiedDate);
                              }
                              else {
                                  props.onDateToChanges(null);
                              }
                          }}
                          onError={(newError) => {
                              setToError(newError);
                          }}
                          minDate={props.dateFrom ?? undefined}
                          inputFormat="dd.MM.yyyy"
                          mask='__.__.____'
                          renderInput={(params) => {
                              return (
                                  <ThemeProvider theme={{
                                      components: {
                                          MuiOutlinedInput: {
                                              styleOverrides: {
                                                  root: {
                                                      width: "152px",
                                                  },
                                                  input: {
                                                      padding: "9px 12px",
                                                      borderRadius: "4px",
                                                      fontSize: "16px",
                                                      fontFamily: "SF Pro Text, serif",
                                                      letterSpacing: "-0.41px",
                                                      '&:hover fieldset': {
                                                          borderColor: "#B2BAC2"
                                                      },
                                                      '&:focus-visible': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                      '&:focus-visible &:hover fieldset': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                  },
                                              }
                                          }
                                      }}}>
                                      <TextField
                                          {...params}
                                          inputProps={{
                                              ...params.inputProps,
                                              placeholder: ""
                                          }}
                                      />
                                  </ThemeProvider>
                              );
                          }}
                      /> :
                      <DesktopDatePicker
                          value={props.dateTo}
                          onChange={(value) => {
                              //К конечной дате добавляем 23 часа 59 минут 59 секунд и 999 мс чтобы поиск был включая конечную дату
                              if (value) {
                                  const dateWithoutTime = value.set({ hour: 0, minute: 0, second: 0, millisecond: 0});
                                  const modifiedDate = DateTime.fromISO(dateWithoutTime).plus({hours: 23, minutes: 59, seconds: 59, milliseconds: 999});

                                  props.onDateToChanges(modifiedDate);
                              }
                              else {
                                  props.onDateToChanges(null);
                              }
                          }}
                          onError={(newError) => {
                              setToError(newError);
                          }}
                          minDate={props.dateFrom ?? undefined}
                          inputFormat="dd.MM.yyyy"
                          mask='__.__.____'
                          renderInput={(params) => {
                              return (
                                  <ThemeProvider theme={{
                                      components: {
                                          MuiOutlinedInput: {
                                              styleOverrides: {
                                                  root: {
                                                      width: "152px",
                                                  },
                                                  input: {
                                                      padding: "8px 12px",
                                                      borderRadius: "4px",
                                                      fontSize: "14px",
                                                      fontFamily: "SF Pro Text, serif",
                                                      letterSpacing: "-0.41px",
                                                      '&:hover fieldset': {
                                                          borderColor: "#B2BAC2"
                                                      },
                                                      '&:focus-visible': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                      '&:focus-visible &:hover fieldset': {
                                                          borderColor: "#1365E3!important",
                                                      },
                                                  },
                                              }
                                          }
                                      }}}>
                                      <TextField
                                          {...params}
                                          inputProps={{
                                              ...params.inputProps,
                                              placeholder: ""
                                          }}
                                      />
                                  </ThemeProvider>
                              );
                          }}
                      />
              }
          </LocalizationProvider>
        </div>
        <div>
          <Typography sx={{ pt: 1, display: 'flex', justifyContent: 'center', color: '#d32f2f' }}>
            {getErrorMessage}
          </Typography>
        </div>
      </div>
    </>
  )
}
