// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerDetails_container__APa7j {\n    padding: 24px;\n    background: #FFFFFF;\n    box-shadow: -3px 3px 16px #0000000F;\n    border-radius: 20px;\n\n    display: flex;\n    flex-direction: column;\n}\n\n.CustomerDetails_title__dNSzl {\n    font-size: 20px;\n    font-weight: 500;\n    margin-bottom: 8px;\n}\n\n.CustomerDetails_customerName__zaqSl {\n    font-size: 17px;\n    margin-bottom: 8px;\n}\n\n.CustomerDetails_gridContainer__sNRWb {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    gap: 12px;\n    margin-top: 24px;\n}\n\n.CustomerDetails_gridColumn__lwNEQ {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/css/CustomerDetails/CustomerDetails.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mCAAmC;IACnC,mBAAmB;;IAEnB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kCAAkC;IAClC,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".container {\n    padding: 24px;\n    background: #FFFFFF;\n    box-shadow: -3px 3px 16px #0000000F;\n    border-radius: 20px;\n\n    display: flex;\n    flex-direction: column;\n}\n\n.title {\n    font-size: 20px;\n    font-weight: 500;\n    margin-bottom: 8px;\n}\n\n.customerName {\n    font-size: 17px;\n    margin-bottom: 8px;\n}\n\n.gridContainer {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    gap: 12px;\n    margin-top: 24px;\n}\n\n.gridColumn {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CustomerDetails_container__APa7j",
	"title": "CustomerDetails_title__dNSzl",
	"customerName": "CustomerDetails_customerName__zaqSl",
	"gridContainer": "CustomerDetails_gridContainer__sNRWb",
	"gridColumn": "CustomerDetails_gridColumn__lwNEQ"
};
export default ___CSS_LOADER_EXPORT___;
