import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    SwipeableDrawer,
    TextField
} from '@mui/material';
import Tree from 'rc-tree';
import '../css/rc-tree.css';
import OkpdService from '../services/OkpdService'
import DialogContent from "@mui/material/DialogContent";
import {styled, ThemeProvider} from "@mui/material/styles";
import {isMobile} from "react-device-detect";
import {grey} from "@mui/material/colors";

export function OkpdSelection(props) {
    const [searchInputTimeout, setSearchInputTimeout] = useState();

    const Puller = styled('div')(({theme}) => ({
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)'
    }));

    useEffect(() => {
        if (props.openOkpdSelectionModal) {
            props.onInitialLoad(props.checkedOkpdCodes);
        }
    }, [props.openOkpdSelectionModal])

    return (
        <React.Fragment>
            {
                isMobile ?
                    <SwipeableDrawer
                        anchor='bottom'
                        open={props.openOkpdSelectionModal}
                        onClose={props.onCloseOkpdSelection}
                        PaperProps={{
                            sx: {
                                width: "100%",
                                borderRadius: "16px 16px 0 0",
                                maxHeight: "80%",
                            }
                        }}
                        scroll={"paper"}
                    >
                        <Puller/>
                        <DialogTitle
                            sx={{
                                m: 0,
                                padding: '32px 16px 12px',
                                fontSize: "20px!important",
                                fontWeight: "600",
                                fontFamily: "SF Pro Text, sans-serif",
                                letterSpacing: "-0.41px"
                            }}
                            id="customized-dialog-title">
                            Выбор ОКПД
                        </DialogTitle>

                        <Grid container spacing="0" alignItems="center" sx={{padding: "0 16px 12px"}}>
                            <ThemeProvider theme={{
                                components: {
                                    MuiOutlinedInput: {
                                        styleOverrides: {
                                            root: {
                                                borderRadius: "4px",
                                                fontSize: "16px",
                                                fontFamily: "SF Pro Text, serif",
                                                letterSpacing: "-0.41px",
                                                '& label.Mui-focused': {
                                                    color: 'var(--TextField-brandBorderFocusedColor)',
                                                },
                                            },
                                        }
                                    }
                                }
                            }}>
                                <TextField
                                    fullWidth
                                    placeholder="Поиск по ОКПД"
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (value) {
                                            if (searchInputTimeout !== null) {
                                                clearTimeout(searchInputTimeout);
                                            }
                                            setSearchInputTimeout(setTimeout(() => {
                                                if (value.replace(' ', '').length > 0) {
                                                    props.onInputOkpdSearch(value);
                                                }
                                            }, 500));
                                        } else {
                                            clearTimeout(searchInputTimeout);
                                            props.onInitialLoad(props.checkedOkpdCodes)
                                        }
                                    }
                                    }
                                />
                            </ThemeProvider>
                            <Grid item>
                                <FormHelperText error sx={{
                                    fontSize: "13px",
                                    fontFamily: "SF Pro Text, serif",
                                    letterSpacing: "-0.41px"
                                }}>
                                    {props.searchWarningMessage}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <DialogContent sx={{borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px", paddingLeft: "12px", paddingRight: "12px"}}>

                            {props.isLoading
                                ?
                                <CircularProgress/>
                                :
                                <Tree
                                    loadData={(node) => props.onChildNodeExpand(node, props.checkedOkpdCodes)}
                                    checkable
                                    treeData={props.OkpdCodes}
                                    checkedKeys={props.checkedOkpdCodes.map(code => {
                                        return code.key;
                                    })}
                                    onCheck={props.onSetCheckedOkpdCodes}
                                    expandedKeys={props.expandedOkpdCodes}
                                    onExpand={props.onOkpdExpanded}
                                    showIcon={false}
                                    className="highlighted"
                                />
                            }
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'flex-start', pt: 2, pb: 3, paddingLeft: '16px'}}>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    backgroundColor: '#2B65F0',
                                    padding: '10px 18px',
                                    width: '120px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    fontWeight: "500",
                                    '&:hover': {
                                        backgroundColor: "#3060DC!important"
                                    }
                                }}
                                variant="contained"
                                onClick={() => props.onOkpdSelected(props.checkedOkpdCodes, props.currentPage)}>Выбрать</Button>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    padding: '10px 18px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    color: "#000000",
                                    fontWeight: "400"
                                }}
                                startIcon={<img src="/close_icon.svg" alt="Close"/>} variant="text"
                                onClick={props.onCloseOkpdSelection}>Отменить</Button>
                        </DialogActions>

                    </SwipeableDrawer> :
                    <Dialog
                        open={props.openOkpdSelectionModal}
                        onClose={props.onCloseOkpdSelection}
                        PaperProps={{
                            sx: {
                                maxWidth: "750px",
                                width: "750px",
                                borderRadius: "16px",
                                maxHeight: "80%"
                            }
                        }}
                        scroll={"paper"}
                    >
                        <DialogTitle sx={{
                            m: 0,
                            p: '24px 24px 0 24px',
                            fontSize: "22px!important",
                            fontWeight: "600",
                            fontFamily: "SF Pro Text, serif",
                            letterSpacing: "-0.41px"
                        }} id="customized-dialog-title">
                            Выбор ОКПД
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={props.onCloseOkpdSelection}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: 16,
                                top: 22,
                                color: theme.palette.grey[500],
                            })}
                        >
                            <img src="/close_icon.svg" alt="Close"/>
                        </IconButton>

                        <Grid container spacing="0" alignItems="center" sx={{p: 3, pb: "12px"}}>
                            <ThemeProvider theme={{
                                components: {
                                    MuiOutlinedInput: {
                                        styleOverrides: {
                                            root: {
                                                borderRadius: "4px",
                                                fontSize: "14px",
                                                fontFamily: "SF Pro Text, serif",
                                                letterSpacing: "-0.41px",
                                                '& label.Mui-focused': {
                                                    color: 'var(--TextField-brandBorderFocusedColor)',
                                                },
                                            },
                                        }
                                    }
                                }
                            }}>
                                <TextField
                                    fullWidth
                                    placeholder="Поиск по ОКПД"
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (value) {
                                            if (searchInputTimeout !== null) {
                                                clearTimeout(searchInputTimeout);
                                            }
                                            setSearchInputTimeout(setTimeout(() => {
                                                if (value.replace(' ', '').length > 0) {
                                                    props.onInputOkpdSearch(value);
                                                }
                                            }, 500));
                                        } else {
                                            clearTimeout(searchInputTimeout);
                                            props.onInitialLoad(props.checkedOkpdCodes)
                                        }
                                    }
                                    }
                                />
                            </ThemeProvider>
                            <Grid item>
                                <FormHelperText error sx={{
                                    fontSize: "13px",
                                    fontFamily: "SF Pro Text, serif",
                                    letterSpacing: "-0.41px"
                                }}>
                                    {props.searchWarningMessage}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <DialogContent sx={{borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px"}}>

                            {props.isLoading
                                ?
                                <CircularProgress/>
                                :
                                <Tree
                                    loadData={(node) => props.onChildNodeExpand(node, props.checkedOkpdCodes)}
                                    checkable
                                    treeData={props.OkpdCodes}
                                    checkedKeys={props.checkedOkpdCodes.map(code => {
                                        return code.key;
                                    })}
                                    onCheck={props.onSetCheckedOkpdCodes}
                                    expandedKeys={props.expandedOkpdCodes}
                                    onExpand={props.onOkpdExpanded}
                                    showIcon={false}
                                    className="highlighted"
                                />
                            }
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'flex-start', pt: 2, pb: 2, paddingLeft: '24px'}}>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    backgroundColor: '#2B65F0',
                                    padding: '10px 18px',
                                    width: '120px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    fontWeight: "500",
                                    '&:hover': {
                                        backgroundColor: "#3060DC!important"
                                    }
                                }}
                                variant="contained"
                                onClick={() => props.onOkpdSelected(props.checkedOkpdCodes, props.currentPage)}>Выбрать</Button>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    padding: '10px 18px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    color: "#000000",
                                    fontWeight: "400"
                                }}
                                startIcon={<img src="/close_icon.svg" alt="Close"/>} variant="text"
                                onClick={props.onCloseOkpdSelection}>Отменить</Button>
                        </DialogActions>

                    </Dialog>
            }
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        OkpdCodes: state.okpdState.OkpdCodes,
        checkedOkpdCodes: state.okpdState.checkedOkpdCodes,
        openOkpdSelectionModal: state.okpdState.openOkpdSelectionModal,
        expandedOkpdCodes: state.okpdState.expandedOkpdCodes,
        isLoading: state.okpdState.isLoading,
        searchWarningMessage: state.okpdState.searchWarningMessage,
        currentPage: state.pagesState.currentPage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onInitialLoad: (selectedCodes) => {
            let selectedCodeKeys = selectedCodes.map(code => {
                return code.key
            });
            OkpdService.getByParent({parentKey: "", selectedCodeKeys: selectedCodeKeys})
                .then((result) => {
                    const action = {
                        type: 'OKPD_INITIATED',
                        OkpdCodes: result
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при получении списка ОКПД',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onChildNodeExpand: (node) => {
            //не перезагружаем элемент если ниже есть выбранные узлы
            //if (node.checked !== true && node.halfChecked !== true) {
            return OkpdService.getByParent({parentKey: node.key, selectedCodeKeys: []})
                .then(result => {
                    const action = {
                        type: 'OKPD_EXPAND_CHILD_NODE',
                        parentCode: node.key,
                        childrenNodes: result
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при получении списка ОКПД',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
            //} else {
            //  return Promise.resolve();
            //}
        },
        onSetCheckedOkpdCodes: (checkedOkpdCodes, event) => {
            if (event.node.pos.length <= 3 && event.node.children.length === 0) {
                OkpdService.getByParent({parentKey: event.node.key, selectedCodeKeys: []})
                    .then(result => {
                        const action = {
                            type: 'OKPD_CODE_CHECKED',
                            checkedOkpdNodesPositions: event.checkedNodesPositions,
                            checkedNode: event.node,
                            childrenNodes: result.map(code => {
                                return code.key
                            })
                        };
                        dispatch(action);
                    })
                    .catch(() => {
                        const notificationAction = {
                            type: 'SNACKBAR_NOTIFICATION_SENT',
                            notification: {
                                message: 'Произошла ошибка при получении списка ОКПД',
                                variant: 'error'
                            }
                        };
                        dispatch(notificationAction);
                    });
            } else {
                const action = {
                    type: 'OKPD_CODE_CHECKED',
                    checkedOkpdNodesPositions: event.checkedNodesPositions,
                    checkedNode: event.node,
                    childrenNodes: []
                };
                dispatch(action);
            }
        },
        onOkpdSelected: (checkedOkpdCodes, currentPage) => {
            const action = {
                type: 'OKPD_SELECTION_COMPLETED',
                checkedOkpdCodes: checkedOkpdCodes,
                currentPage: currentPage
            };
            dispatch(action);
        },
        onCloseOkpdSelection: () => {
            const action = {
                type: 'OKPD_SELECTION_CLOSED'
            };
            dispatch(action);
        },
        onInputOkpdSearch: (searchText) => {
            const action = {
                type: 'OKPD_SEARCH_STARTED'
            };
            dispatch(action);
            OkpdService.search(searchText)
                .then((result) => {
                    const action = {
                        type: 'OKPD_SEARCHED',
                        searchResults: result,
                        searchText: searchText
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const action = {
                        type: 'OKPD_SEARCH_FAILED'
                    };
                    dispatch(action);
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при поиске по ОКПД',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                })
        },
        onOkpdExpanded: (expandedOkpdCode) => {
            const action = {
                type: 'OKPD_EXPANDED',
                expandedOkpdCode: expandedOkpdCode
            };
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OkpdSelection)

