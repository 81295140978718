import React, {Component} from 'react';
import {withRouter} from '../withRouter';
import CustomerService from '../services/CustomerService';
import CustomerRatingService from '../services/CustomerRatingService';
import {connect} from 'react-redux';
import styles from '../css/CustomerDetails/CustomerDetails.module.css'

// MUI:
import {Box, Rating, Typography} from '@mui/material';

class CustomerDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            averageRating: 0,
            customer: null
        };
    }

    componentDidMount() {
        const customerOrganizationId = this.props.customerOrganizationId;
        if (customerOrganizationId) {
            CustomerService.getCustomer(customerOrganizationId)
                .then(customer => {
                    this.setState({customer: customer});
                })
                .catch(() => {
                    this.props.onSendError("Не удалось получить данные по компании");
                });
            CustomerRatingService.getCustomerRating(customerOrganizationId)
                .then(rating => {
                    this.setState({averageRating: parseFloat(rating)});
                })
                .catch(error => {
                    this.props.onSendError("Не удалось получить данные по оценкам компании");
                });
        }
    }


    render() {
        if (this.state.customer) {
            return (
                <React.Fragment>
                    <Box className={styles.container}>
            <span className={styles.title}>
              Заказчик
            </span>
                        <span className={styles.customerName}>
              {this.state.customer.name}
            </span>

                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Box sx={{color: 'text.secondary', mr: 1}}>
                                Средняя оценка
                            </Box>
                            <Box>
                                <Rating value={this.state.averageRating} precision={0.1} readOnly/>
                            </Box>
                        </Box>

                        <div className={styles.gridContainer}>
                            <div className={styles.gridColumn}>
                                {!!this.state.customer.kpp &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            КПП
                                        </Box>
                                        <Box>
                                            {this.state.customer.kpp}
                                        </Box>
                                    </Box>
                                }

                                {!!this.state.customer.inn &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            ИНН
                                        </Box>
                                        <Box>
                                            {this.state.customer.inn}
                                        </Box>
                                    </Box>
                                }

                                {!!this.state.customer.ogrn &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            ОГРН
                                        </Box>
                                        <Box>
                                            {this.state.customer.ogrn}
                                        </Box>
                                    </Box>
                                }
                            </div>

                            <div className={styles.gridColumn}>
                                {!!this.state.customer.sourceRegion &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            Регион
                                        </Box>
                                        <Box>
                                            {this.state.customer.sourceRegion}
                                        </Box>
                                    </Box>
                                }

                                {this.state.customer.sourceActualAddress &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            Место нахождения
                                        </Box>
                                        <Box>
                                            {this.state.customer.sourceActualAddress}
                                        </Box>
                                    </Box>
                                }

                                {this.state.customer.sourcePostalAddress &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            Почтовый адрес
                                        </Box>
                                        <Box>
                                            {this.state.customer.sourcePostalAddress}
                                        </Box>
                                    </Box>
                                }

                                {this.state.customer.responsiblePerson &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            Ответственное лицо
                                        </Box>
                                        <Box>
                                            {this.state.customer.responsiblePerson}
                                        </Box>
                                    </Box>
                                }
                            </div>

                            <div className={styles.gridColumn}>
                                {this.state.customer.emails.length > 0 &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            Электронная почта
                                        </Box>
                                        <Box>
                                            {this.state.customer.emails.map((email, key) =>
                                                <Typography key={key}>{email}</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                }

                                {this.state.customer.phones.length > 0 &&
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{color: '#8A8A8E', fontSize: "14px"}}>
                                            Контактный телефон
                                        </Box>
                                        <Box>
                                            {this.state.customer.phones.map((phone, key) =>
                                                <Typography key={key}>{phone}</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                }
                            </div>
                        </div>

                    </Box>

                </React.Fragment>
            )
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSendError: (errorMessage) => {
            const notificationAction = {
                type: 'SNACKBAR_NOTIFICATION_SENT',
                notification: {
                    message: errorMessage,
                    variant: 'error'
                }
            };
            dispatch(notificationAction);
        },
    }
}


export default connect(null, mapDispatchToProps)(withRouter(CustomerDetails));
